'use strict';

import Validation from '../../model/util/validation';
import LocationUtil from '../../model/util/location-util';
import ShareOrderDialogTemplate from './share-order-dialog.html';
import {Locations, TrackingIds} from '../../services/mixpanel/mixpanel.service';
import moment from 'moment';

export default class ShareOrderDialogController {

  /**
   * @ngInject
   */

  constructor($scope, $log, $mdDialog, $mdToast, $location, $window, AnalyticsService, OrderService, CacheService, AuthService,
              StorageService, user, order, from, to, subject, body, activeContract, isDistrictRenewal, clickedFrom,
              mdrOrganizationAdmins) {

    this.$log = $log;
    this.$mdDialog = $mdDialog;
    this.$window = $window;
    this.$mdToast = $mdToast;
    this.$location = $location;

    /** @type {AuthService} */
    this._authService = AuthService;
    /** @type {CacheService} */
    this._cacheService = CacheService;
    /** @type {OrderService} */
    this._orderService = OrderService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {StorageService} */
    this._storageService = StorageService;

    this._state = this.DraftEmail;
    this._activeContract = activeContract;
    this._clickedFrom = clickedFrom;
    this._mdrOrganizationAdmins = mdrOrganizationAdmins;

    this._user = user;
    this._order = order;
    this._from = from || '';
    this._to = to || '';
    this.emailRegex = Validation.EmailRegex;
    this._subject = subject || '';
    this._body = body || '';
    this._orderShareInternalEmailCc = isDistrictRenewal ? ['pro@classkick.com', 'partnerships@classkick.com'] : ['pro@classkick.com'];
    this._fromName = undefined;
    this._toastMessage = '';
    this._invalidEmails = '';
    this.invalidFromEmail = false;
    this.invalidSubjectLine = false;
  }

  /**
   * @return {Boolean}
   */
  get isLoggedIn () {
    return this._authService.isLoggedIn;
  }

  /**
   * @return {string}
   */
  get state() {
    return this._state;
  }

  /**
   * @param value {string}
   */
  set state(value) {
    this._state = value;
  }

  /**
   * @return {string}
   */
  get invalidEmails() {
    return this._invalidEmails;
  }

  /**
   * @param value {string}
   */
  set invalidEmails(value) {
    this._invalidEmails = value;
  }

  get Loading() {
    return 'loading';
  }

  get nav() {
    return Locations.NAV;
  }

  get header(){
    return Locations.HEADER;
  }

  get DraftEmail() {
    return 'draft_email';
  }

  get EmailSent() {
    return 'email_sent';
  }

  get EmailError() {
    return 'email_error';
  }

  get TrialEmailExtension() {
    return 'trial_email_extensions';
  }

  get TrialEmail() {
    return 'trial_email';
  }

  get isFromBasePage() {
    return this._clickedFrom === this.nav || this._clickedFrom === Locations.HEADER;
  }

  get isFromOrderPage(){
    return this._clickedFrom === Locations.ORDER_DETAILS || this._clickedFrom === Locations.ORDER_PAGE;
  }

  get isFromMetricsPage(){
    return this._clickedFrom === Locations.METRICS;
  }

  get isFromFeatureChart(){
    return this._clickedFrom === Locations.FEATURE_CHART;
  }

  get buttonText() {
    return 'SEND';
  }

  get isFreeTrial() {
    return this._activeContract && this._activeContract.isTrial;
  }

  get isProSchoolOrDistrict() {
    return this._activeContract
      && !this._activeContract.isTrial
      && (this._activeContract.isProDistrict || this._activeContract.isProSchool);
  }

  get from() {
    return this._from;
  }

  set from(value) {
    this._from = value;
  }

  get to() {
    return this._to;
  }

  set to(value) {
    this._to = value;
  }

  get activeContract () {
    return this._activeContract;
  }

  /**
   * tests list of emails against the list of emails regex.
   * @return {Boolean} whether the email list is valid or not
   */
  get isEmailListValid() {
    let emails = this.to.split(/[,;]/);

    if (emails.length > 10) {
      return false;
    }

    let isValid;

    for (let email of emails) {
      email = email.trim();
      isValid = this.emailRegex.test(email);
      if (!isValid) {
        return false;
      }
    }

    return true;
  }

  get isFromEmailValid() {
    const email = this.from && this.from.trim();
    if (!email ){
      return false;
    }
    if (email.length && email.split(/[,;]/).length > 1) {
      return false;
    }
    let isValid;
    isValid = this.emailRegex.test(email);
    if (!isValid) {
      return false;
    }

    return true;
  }

  get subject() {
    return this._subject;
  }

  set subject(value) {
    this._subject = value;
  }

  get body() {
    return this._body;
  }

  set body(value) {
    this._body = value;
  }

  get clickedFrom (){
    return this._clickedFrom;
  }

  submitForm(scope) {

    if (!this.isEmailListValid) {
      if (scope.shareOrderForm.toInput) {
        scope.shareOrderForm.toInput.$setValidity('pattern', false);
      }
      return this._invalidEmails = !this.isEmailListValid;
    }

    if (!this.isFromEmailValid) {
      return this.setFromEmailValidity(scope);
    }

    if (this.invalidSubjectLine) {
      return this.setValidSubjectLine(scope);
    }

    else if (!this.body) {
      return;
    }

    if (this.isFromMetricsPage) {
      this._analyticsService.metricsEmailSent(this._order && this._order.id);
      return this.shareMetrics();
    } else if (this.isFromFeatureChart) {
      this._analyticsService.shareOrderDialogSent(Locations.FEATURE_CHART);
      return this.shareFeatures();
    }
    else {
      let trackingInfo = {
        orderId: this._order.id,
        trackingId: TrackingIds.EMAIL_SENT,
        userId: this._user ? this._user.id : null,
        source: this._clickedFrom,
        targets: this._to.split(/[,;]/).map((email) => email.trim())
      };
      this._orderService.track(trackingInfo);
      this._analyticsService.shareOrderDialogSent(this._clickedFrom, this.to);
    }
    this._storageService.sentAdminEmail = true;
    this.shareOrder();
  }

  shareOrder() {
    const body = this.formatBody();

    this._state = this.Loading;

    if (this._user) {
      this._fromName = `${this._user.firstLastCombo} via Classkick`;
    } else {
      this._fromName = `${this._from} via Classkick`;
    }

    this._orderService
      .share(
        this._order.id,
        this._from,
        this._to.split(/[,;]/).map((email) => email.trim()),
        this._orderShareInternalEmailCc,
        this._subject,
        body,
        this._fromName,
        {
          user_id: this._user ? this._user.id : null,
          event_tag: `${this._clickedFrom}:shareOrderDialog_sent`
        }
      )
      .then((data) => {
        if (data.extension && data.extension === true) {
          this._state = this.TrialEmailExtension;
        } else if (this.isFreeTrial || this.isFromOrderPage) {
          this.displayToastMessage('You\'ve successfully sent the quote page to your administrator', undefined,true);
          this.$mdDialog.cancel();
        } else if (this.isInAppRenewalEmail()){
          this.displayToastMessage('Message sent to your admin!', undefined,true);
          this.$mdDialog.cancel();
        } else {
          this._state = this.EmailSent;
        }
        if (!this._order.billingEmail) {
          this._order.billingEmail = this.to;
          this._orderService.update(this._order);
        }
      })
      .catch(() => {
        this._state = this.EmailError;
      });
  }

  shareMetrics() {
    const body = this.formatBody();

    this._state = this.Loading;

    if (this._user) {
      this._fromName = `${this._user.firstLastCombo} via Classkick`;
    } else {
      this._fromName = `${this._from} via Classkick`;
    }

    this._orderService.share(
      this._order ? this._order.id : '000000',
      this._from,
      this._to.split(/[,;]/).map((email) => email.trim()),
      this._orderShareInternalEmailCc,
      this._subject,
      body,
      this._fromName,
      {
        user_id: this._user ? this._user.id : null,
        event_tag: 'metrics:metricsEmail_sent'
      }
    ).then((data) => {
      this._state = this.EmailSent;

    }).catch(() => {
      this._state = this.EmailError;
    });
  }

  shareFeatures() {
    const body = this.formatBody();

    this._state = this.Loading;

    if (this._user) {
      this._fromName = `${this._user.firstLastCombo} via Classkick`;
    } else {
      this._fromName = `${this._from} via Classkick`;
    }
    
    this._orderService.shareFeatureChart(
      this._order ? this._order.id : '000000',
      this._from,
      this._to.split(/[,;]/).map((email) => email.trim()),
      undefined,
      this._fromName,
      this.subject,
      body
    ).then((data) => {
      this._state = this.EmailSent;

    }).catch(() => {
      this._state = this.EmailError;
    });
  }

  formatBody() {
    return this._body.split('\n').join('<br>');
  }

  setValidSubjectLine(scope){
    this.invalidSubjectLine = !this._subject.length > 0;
    scope.shareOrderForm.subjectInput.$setValidity('pattern', this._subject.length > 0);
  }

  setFromEmailValidity(scope) {
    this.invalidFromEmail = !this.isFromEmailValid;
    scope.shareOrderForm.fromInput.$setValidity('pattern', this.isFromEmailValid);
  }

  cancel() {
    if (this.isFromMetricsPage) {
      this._analyticsService.shareMetricsDialogDismissed(this._order && this._order.id);
    }
    if (this.state !== this.TrialEmail && !this.isFromMetricsPage) {
      this._analyticsService.shareOrderDialogDismissed(this._clickedFrom);
    }
    this.$mdDialog.cancel();
    if (this.state === this.TrialEmailExtension) {
      this.$window.location.reload();
    }
  }

  copyInviteText() {
    navigator.clipboard.writeText(this.body).then(() => {
      this.displayToastMessage('Successfully copied to clipboard', this.isFromOrderPage);
      this._analyticsService.copyShareOrderText(this._clickedFrom, this._order && this._order.id);
    }).catch((error) => {
      this.$log.error(error);
      this.displayToastMessage('Sorry there was an error copying message');
    });
  }

  displayToastMessage(message, isFromOrderPage, isAdminEmail = false) {
    this._toastMessage = message;

    let toastClassName = isFromOrderPage ? 'order-toast' : 'referral-toast';
    if (isAdminEmail) {
      toastClassName = 'email-admin-toast';
      if (this.$location.$$path.includes('/order')) {
        toastClassName = 'email-admin-toast order-page';
      }
    }
    const config = this.$mdToast.simple(this._toastMessage).action(' ').actionKey('x').position('top right').toastClass(toastClassName).hideDelay(4000);
    this.$mdToast.show(config);
  }

  isRenewalPeriod(){
    const currentMonth = moment().month();
    const oneMonthAgo = moment().month(currentMonth - 1);
    const sixMonthsFromNow = moment().month(currentMonth + 6);
    if (this._activeContract && !this._activeContract.isTrial &&
      (this._activeContract.expires >= oneMonthAgo && this._activeContract.expires <= sixMonthsFromNow)) {
      return true;
    }
    if (currentMonth >= 2) {
      // We consider any school with a contract lasting past July 31 to have a
      // contract for next year.
      let year = moment().year();
      let schoolYearThreshold = new Date(year, 7, 1);
      if (this._activeContract && this._activeContract.expires < schoolYearThreshold) {
        return true;
      }
    }

    return false;
  }

  getShareOrderDialogTitle(){
    if (this.isFromMetricsPage) {
      return 'Send Metrics to Your Admin';
    }

    if (this.isFromFeatureChart) {
      return 'Share Plans With Your Admin';
    }
    //for renewal orgs - in the app only
    if (this.isInAppRenewalEmail()) {
      return `Remind your  ${this.activeContract && this.activeContract.isProDistrict ? 'district' : 'school'} to renew`;
    }
    return 'Send This Quote to Your Admin';
  }

  isInAppRenewalEmail(){
    return this.isRenewalPeriod() && this.isProSchoolOrDistrict && this.isFromBasePage;
  }

  /**
   * Launches share order dialog
   * @param $mdDialog
   * @param user
   * @param order {Order}
   * @param from {string}
   * @param to {string[]  | undefined}
   * @param subject {string}
   * @param body {string}
   * @param activeContract {Contract | undefined} contract for the order's organization
   * @param isDistrictRenewal {boolean | undefined}
   * @param clickedFrom {string | undefined}
   * @param mdrOrganizationAdmins {MdrOrganizationAdminCodec | undefined} (Optional} decoded MdrOrganizationAdmin
   * @returns {*}
   */
  static show($mdDialog, user, order, from, to, subject, body,
              activeContract, isDistrictRenewal, clickedFrom,
              mdrOrganizationAdmins= undefined) {
    return $mdDialog.show({
      controller: ShareOrderDialogController,
      template: ShareOrderDialogTemplate,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      locals: {
        user,
        order,
        from,
        to,
        subject,
        body,
        activeContract,
        isDistrictRenewal,
        clickedFrom,
        mdrOrganizationAdmins
      }
    });
  }


  static get DefaultTitle() {
    return 'I found a great opportunity to get Classkick!';
  }

  static get MetricsTitle() {
    return 'Check out our Classkick usage!';
  }

  static get FeatureChartTitle() {
    return 'Check out our Classkick plan options!';
  }

  static get OrganizationRenewalTitle() {
    return 'Sharing about my Classkick experience';
  }

  static formatDefaultMessage(orderId, orgType, $location) {
    return `Hi, \n\nI've been using Classkick in my classroom this year and my students love it! \n\nIf our school upgrades to Classkick Pro then my colleagues and I can use it to give our students real-time feedback to help them learn. A Classkick Pro School Membership would be great for our teachers and students and let us use unlimited assignments on Classkick. \n\nClasskick is offering a special promotion. Here's a link to a quote: ${LocationUtil.absRootUrl($location)}/order/${orderId} \n\nCan we take advantage of this promotion soon so that we can keep supporting learning with unlimited student practice, feedback and support? \n\nThank you!\n`;
  }

  static formatDistrictRenewalMessage(expirationDate, isExpired) {
    return `Hi, \n\nI’ve been using Classkick in my classroom this year and my students love it! It saves me time grading and allows me to provide my students with feedback in real time. \n\nOur Classkick Pro subscription expire${isExpired ? 'd' : 's'} on ${expirationDate} and I want to continue to access the tool. Classkick is offering a special promotion right now so that we can maintain our access at a discounted rate. \n\nCan we take advantage of this offer soon so that those of us using Classkick don’t run out of assignments? The Classkick team will follow up with you on my behalf. \n\nThank you!\n\n`;
  }

  static formatEmailForMetricsOnly(name, $location, organizationUsageStats) {
    let emailContent = `Hi,\n\nI've been using Classkick in my classroom and wanted to share some exciting metrics about our school's usage this year:\n${this.getUsageStatsText(organizationUsageStats)}\n\nClasskick lets me see students' thinking in real-time, saves so much time, lets us eliminate other unnecessary apps, and MUCH more. Here's a video of Classkick's magic. https://youtu.be/l5vza3HTW5I. We love it.\n\nThank you for ensuring we continue to have this tool for our students!`;

    if (name) {
      emailContent += `\n\nBest, \n${name}`;
    }

    return emailContent;
  }

  static formatEmailForMetricsWithQuote(name, orderId, $location, organizationUsageStats) {
    let emailContent = `Hi,\n\nI've been using Classkick in my classroom this year and my students love it!\n\nHere are some exciting metrics about our school's usage this year:\n${this.getUsageStatsText(organizationUsageStats)}\n\nIf our school upgrades to Classkick Pro, then my colleagues and I can use it to give our students real-time feedback to help them learn. A Classkick Pro School Membership would be great for our teachers and students and let us use unlimited assignments on Classkick.\n\nClasskick is offering a special promotion right now. Here's a link to a quote: ${LocationUtil.absRootUrl($location)}/order/${orderId}\n\nCan we take advantage of this promotion soon so that we can keep supporting learning with unlimited student practice, feedback, and support?`;

    emailContent += name ? `\n\nBest, \n${name}`:'\n\nThank you!';

    return emailContent;
  }

  static getUsageStatsText(organizationUsageStats) {
    const {
      teachersActiveThisYear,
      studentsActiveThisYear,
      assignmentsWorkedOnThisYear,
      pagesWorkedOnThisYear,
      approximateSavingsDisplay,
    } = organizationUsageStats || {};

    let statsText = '';

    if (teachersActiveThisYear) {
      statsText += `\nTeachers Active This Year: ${teachersActiveThisYear.toLocaleString()}`;
    }

    // if (studentsActiveThisYear) {
    //   statsText += `\nStudents Active This Year: ${studentsActiveThisYear.toLocaleString()}`;
    // }

    if (assignmentsWorkedOnThisYear) {
      statsText += `\nTotal Assignments Worked on This Year: ${assignmentsWorkedOnThisYear.toLocaleString()}`;
    }

    if (pagesWorkedOnThisYear) {
      statsText += `\nPages Worked on This Year: ${pagesWorkedOnThisYear.toLocaleString()}`;
    }

    if (approximateSavingsDisplay) {
      statsText += `\nApproximate Yearly $ Savings on Ink, Copies, and Paper: ${approximateSavingsDisplay}`;
    }

    return statsText;
  }

  static formatOrderEmailForFreeTrialSchools(orderId, name, $location, currentPrice) {
    let copy = `Hi, \n\nA Classkick school subscription (which includes unlimited teacher licenses!) normally costs $1,999 for the 2023-24 school year. However, for schools that purchase a new plan this year, we can get early-bird pricing of ${currentPrice}.\n\nQUOTE ${LocationUtil.absRootUrl($location)}/order/${orderId}\n\nIt lets me see students' thinking in real-time, saves so much time, lets us eliminate other unnecessary apps, and MUCH more. Here's a video of Classkick's magic. https://youtu.be/l5vza3HTW5I. We love it. Can our teachers have access to Classkick next year?\n\n`;
    copy += name ? `Best,\n${name}` : 'Thank you!\n';
    return copy;
  }

  static formatOrderEmailForFreeTrialSchoolsOver1999(orderId, name, $location, currentPrice) {
    const price = parseInt(currentPrice.replace(/[^\d.]/g, ''));
    let copy = `Hi, \n\nA Classkick school subscription (which includes unlimited teacher licenses!) normally costs ${price >= 2999 ? '$3,999' : '$2,999'} for the 2023-24 school year. However, for schools that purchase a new plan this year, we can get a discounted pricing of ${currentPrice}.\n\nQUOTE ${LocationUtil.absRootUrl($location)}/order/${orderId}\n\nIt lets me see students' thinking in real-time, saves so much time, lets us eliminate other unnecessary apps, and MUCH more. Here's a video of Classkick's magic. https://youtu.be/l5vza3HTW5I. We love it. Can our teachers have access to Classkick next year?\n\n`;
    copy += name ? `Best,\n${name}` : 'Thank you!\n';
    return copy;
  }

  static formatOrderEmailForFreeTrialSchoolsWithDistrictQuote(orderId, name, $location, currentPrice, numberOfSchools, districtOrderId) {
    let copy = `Hi, \n\nClasskick lets me see students' thinking, support them instantly, and increase on-task curriculum focus. It saves so much time, eliminates unnecessary apps, and MUCH more. Here's a video of Classkick's magic. https://youtu.be/l5vza3HTW5I. We love it. Can our teachers have access to Classkick next year?\n\nAn unlimited subscription for Classkick normally costs $1,999/year. However, we can get a discount at ${currentPrice} for this year.\n\nSCHOOL QUOTE ${LocationUtil.absRootUrl($location)}/order/${orderId}\n\nDistrict quotes are even more cost effective\n\nDISTRICT BUY 1 GET 1 ${LocationUtil.absRootUrl($location)}/order/${districtOrderId}\nor a 6-MONTH PILOT for our ${numberOfSchools} ${numberOfSchools === 1 ? 'school' : 'schools'} at ${numberOfSchools < 30 ? '$1,999' : '$4,999'}\n\n`;
    copy +=  name ? `Best,\n${name}` : 'Thank you!\n';
    return copy;
  }

  static formatShareFeatureChart(orderId, name, $location) {
    let copy = 'Hi there, \n\nClasskick lets me see students\' thinking in real-time, saves so much time, lets us eliminate other unnecessary apps, and MUCH more. Here\'s a video of Classkick\'s magic. https://youtu.be/l5vza3HTW5I. We love it. Can our teachers have access to Classkick next year?\n\n';

    if (orderId) {
      copy += `Here's a link to a quote: ${LocationUtil.absRootUrl($location)}/order/${orderId} \n\n`;
    }

    copy += name ? `Best,\n${name}` : 'Thank you!\n';
    return copy;
  }


  static formatOrderEmailForFreeTrialSchoolsOver1999WithDistrictQuote(orderId, name, $location, currentPrice, numberOfSchools, districtOrderId) {
    const price = parseInt(currentPrice.replace(/[^\d.]/g, ''));
    let copy = `Hi, \n\nClasskick lets me see students' thinking, support them instantly, and increase on-task curriculum focus. It saves so much time, eliminates unnecessary apps, and MUCH more. Here's a video of Classkick's magic. https://youtu.be/l5vza3HTW5I. We love it. Can our teachers have access to Classkick next year?\n\nAn unlimited subscription for Classkick normally costs ${price >= 2999 ? '$3,999' : '$2,999'}/year. However, we can get a discount at ${currentPrice} for this year.\n\nSCHOOL QUOTE ${LocationUtil.absRootUrl($location)}/order/${orderId}\n\nDistrict quotes are even more cost effective\n\nDISTRICT BUY 1 GET 1 ${LocationUtil.absRootUrl($location)}/order/${districtOrderId}\nor a 6-MONTH PILOT for our ${numberOfSchools} ${numberOfSchools === 1 ? 'school' : 'schools'} at ${numberOfSchools < 30 ? '$1,999' : '$4,999'}\n\n`;
    copy += name ? `Best,\n${name}` : 'Thank you!\n';
    return copy;
  }


  static formatOrderEmailForOrgRenewals(name, generatedOrderLink) {
    let copy = `Hi, \n\nOur Classkick subscription ends soon, so I just wanted send a friendly reminder of why we love it and how it's crucial for student success! ${generatedOrderLink ? `\n\nRENEWAL QUOTE: ${generatedOrderLink}` : ''}\n\nClasskick lets me see students' thinking, and ensures my kids get the support they need directly on their work. It delivers the curriculum much more thoroughly and checks for understanding more frequently. It's special. I've tried many apps, and while Classkick supplants many of them, nothing can replace Classkick. Many apps I've tried claim to be “real-time”, but they aren't. Only Classkick is, and I can run my whole classroom on it. It also just saves time. The more hours a week I save, the more I can be there for my students.\n\nSo can we please re-subscribe for next year? Thank you!`;
    copy += name ? `\n\n${name}` : '\n';
    return copy;
  }
}
