import { StrictMode } from "react"

import { IDirective } from "angular"
import { createRoot } from "react-dom/client"

import Order from "../../app/model/domain/order"
import { OrganizationsUsageStats } from "../../app/model/domain/organization-usage-stats"
import { MetricsPage } from "./MetricsPage"

export function MetricsPageDirective(): IDirective {
  return {
    restrict: "E",
    controller: "MetricListController",
    link: function (scope, element, _attributes, controller) {
      const getOrganizationsUsageStats: () => Promise<
        OrganizationsUsageStats[]
      > = controller?._organizationService.getOrganizationsUsageStats.bind(
        controller._organizationService,
      )

      const getActiveOrder: (organizationId: string) => Promise<Order> =
        controller?._orderService.getActiveOrder.bind(controller._orderService)

      const openShareModal = controller?.shareMetrics.bind(controller)

      const root = createRoot(element[0])
      root.render(
        <StrictMode>
          <MetricsPage
            getOrganizationsUsageStats={getOrganizationsUsageStats}
            getActiveOrder={getActiveOrder}
            openShareModal={openShareModal}
          />
        </StrictMode>,
      )

      scope.$on("$destroy", () => {
        root.unmount()
      })
    },
  }
}
