
'use strict';

export default class SignupChoiceController {

  constructor(BreadcrumbService, AnalyticsService, LogRocketService) {
    'ngInject';

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;
    /** @type {AnalyticsService} */
    this._analyticsService = AnalyticsService;
    /** @type {LogRocketService} */
    this._logRocketService = LogRocketService;

    this._isLoading = false;

    this._analyticsService.sendEvent({
      eventTag: 'page_viewed',
      properties: {
         location: 'signupChoice'
      }
    });
  }

  /**
   * @return {boolean}
   */
  get isLoading() {
    return this._isLoading;
  }

  goBack() {
    this._breadcrumbService.goBack('root.login');
  }

  goToCreateTeacherAccount() {
    this._analyticsService.sendEvent({
      eventTag: 'signup:teacher_clicked'
    });
    this._breadcrumbService.go('root.signup-teacher');
  }

  goToProInviteStudent() {
    this._analyticsService.sendEvent({
      eventTag: 'signup:student_clicked'
    });
    this._breadcrumbService.go('root.signup-student');
  }

}
