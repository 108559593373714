import { cn } from "@/lib/classnames"

export function LoadingOverlay() {
  return (
    <div
      className={cn(
        "tw-fixed tw-inset-0 tw-bg-black/50 tw-cursor-wait",
        "tw-flex tw-items-center tw-justify-center tw-z-[100]",
      )}
    >
      <div className="tw-flex-col tw-items-center tw-justify-center tw-text-center">
        <i className="ck ck-loading fa fa-spin fa-4x tw-text-primary"></i>
        <div className="tw-text-white tw-p-4">Loading...</div>
      </div>
    </div>
  )
}
