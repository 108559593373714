import { ButtonHTMLAttributes, forwardRef } from "react"

import { type VariantProps, cva } from "class-variance-authority"

import { cn } from "@/lib/classnames"

const buttonVariants = cva(
  cn(
    "tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-whitespace-nowrap",
    "tw-ring-offset-background tw-transition-colors",
    "focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-offset-1 focus-visible:tw-ring-ring",
    "disabled:tw-pointer-events-none disabled:tw-opacity-50",
    "[&_svg]:tw-pointer-events-none [&_svg]:tw-size-4 [&_svg]:tw-shrink-0",
  ),
  {
    variants: {
      variant: {
        default:
          "tw-bg-primary tw-text-primary-foreground hover:tw-bg-primary/90",
        destructive:
          "tw-bg-destructive tw-text-destructive-foreground hover:tw-bg-destructive/90",
        outline:
          "tw-border tw-border-input tw-bg-background hover:tw-bg-accent hover:tw-text-accent-foreground",
        secondary:
          "tw-bg-secondary tw-text-secondary-foreground hover:tw-bg-secondary/80",
        ghost: "hover:tw-bg-accent hover:tw-text-accent-foreground",
        link: "tw-text-primary tw-underline-offset-4 hover:tw-underline",
      },
      size: {
        default: "tw-h-12 tw-px-4 tw-rounded-lg",
        sm: "tw-h-7 tw-px-3 tw-rounded-full tw-text-sm",
        lg: "tw-h-11 tw-px-8 tw-rounded-md",
        icon: "tw-h-10 tw-w-10 tw-rounded-md",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = "Button"

export { Button, buttonVariants }
