'use strict';

import StudentAccountHelpDialogController from '../student-account-help-dialog/student-account-help-dialog.controller';
import StudentInfoMenuTemplate from './student-info-menu.html';

export class StudentInfoMenuController {

  /**
   * @ngInject
   */
  constructor($mdDialog, mdPanelRef, deferred, BreadcrumbService, user, rosterId) {
    this.$mdDialog = $mdDialog;
    
    this._mdPanelRef = mdPanelRef;
    this._deferred = deferred;
    this._user = user;
    this._rosterId = rosterId;

    /** @type {BreadcrumbService} */
    this._breadcrumbService = BreadcrumbService;

    this._showStudentAccountHelpDialog = StudentAccountHelpDialogController.show;
  }

  /**
   * @return {string}
   */
  get lastSeenDisplay() {
    return this._lastSeenDisplay;
  }

  /**
   * @param value {string}
   */
  set lastSeenDisplay(value) {
    this._lastSeenDisplay = value;
  }

  /**
   * @return {User}
   */
  get user() {
    return this._user;
  }

  /**
   * @param value {User}
   */
  set user(value) {
    this._user = value;
  }

  /**
   * @return {ProInfo}
   */
  get pro() {
    return this._pro;
  }

  /**
   * @param value {ProInfo}
   */
  set pro(value) {
    this._pro = value;
  }

  /**
   * @return {string}
   */
  get rosterId() {
    return this._rosterId;
  }

  /**
   * @param value {string}
   */
  set rosterId(value) {
    this._rosterId = value;
  }

  /**
   * @return {boolean}
   */
  get isBasic() {
    return !!this._user.displayName;
  }

  showAccountTypeHelp() {
    this.cancel();
    this._showStudentAccountHelpDialog(this.$mdDialog, this.pro);
  }

  cancel() {
    this._deferred.reject();
    this._mdPanelRef.hide();
  }

  /**
   * @param $mdPanel
   * @param $q
   * @param $event
   * @param user {User}
   * @param lastSeenDisplay {string}
   * @param pro {ProInfo}
   * @return {Promise}
   */
  static show($mdPanel, $q, $event, user, lastSeenDisplay, pro, rosterId) {

    const position = $mdPanel.newPanelPosition()
      .relativeTo($event.currentTarget)
      .addPanelPosition(
        $mdPanel.xPosition.ALIGN_START,
        $mdPanel.yPosition.ALIGN_TOPS
      );

    const deferred = $q.defer();

    $mdPanel.open({
      position,
      animation: $mdPanel.newPanelAnimation()
        .openFrom($event.currentTarget)
        .duration(100)
        .withAnimation($mdPanel.animation.SCALE),
      template: StudentInfoMenuTemplate,
      controller: StudentInfoMenuController,
      controllerAs: 'ctrl',
      clickOutsideToClose: true,
      escapeToClose: true,
      panelClass: 'student-info-menu',
      locals: {
        deferred,
        user,
        lastSeenDisplay,
        pro,
        rosterId
      },
      onRemoving: () => {
        deferred.reject();
      }
    });

    return deferred.promise;
  }

  goToStudentOverview(){
    this.cancel();
    this._breadcrumbService.go(
      'root.account.student-overview',
      {
        userId: this.user.id,
        rosterId: this.rosterId
      });
  }
}
