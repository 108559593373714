"use client"

import { useEffect, useState } from "react"

import { ColumnDef, createColumnHelper } from "@tanstack/react-table"

import { LoadingOverlay } from "@/components/LoadingOverlay"
import { Button } from "@/components/ui/Button"

import Order from "../../app/model/domain/order"
import {
  OrganizationUsageStats,
  OrganizationsUsageStats,
} from "../../app/model/domain/organization-usage-stats"
import { DataTable } from "./DataTable"

const columnHelper = createColumnHelper<OrganizationsUsageStats>()

interface MetricsPageProps {
  getOrganizationsUsageStats: () => Promise<OrganizationsUsageStats[]>
  getActiveOrder: (organizationId: string) => Promise<Order>
  openShareModal: (
    organizationId: string,
    usageStats: OrganizationUsageStats,
    order: Order,
  ) => void
}

export function MetricsPage({
  getOrganizationsUsageStats,
  getActiveOrder,
  openShareModal,
}: MetricsPageProps) {
  const [data, setData] = useState<OrganizationsUsageStats[] | undefined>()
  const [isLoadingOverlayVisible, setIsLoadingOverlayVisible] = useState(false)

  useEffect(() => {
    getOrganizationsUsageStats().then(setData)
  }, [getOrganizationsUsageStats])

  async function handleShareClick(
    organizationId: string,
    usageStats: OrganizationUsageStats,
  ): Promise<void> {
    setIsLoadingOverlayVisible(true)
    const order = await getActiveOrder(organizationId)
    openShareModal(organizationId, usageStats, order)
    setIsLoadingOverlayVisible(false)
  }

  function handleInviteClick(organizationId: string) {
    // TODO: trigger invite modal
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnDef<OrganizationsUsageStats, any>[] = [
    columnHelper.accessor("organization.name", { header: "School" }),
    columnHelper.accessor("stats.teachersActiveThisYear", {
      header: "Active teachers",
    }),
    columnHelper.accessor("stats.assignmentsWorkedOnThisYear", {
      header: "Assignments worked",
      cell: ({ getValue }) => getValue()?.toLocaleString() ?? null,
    }),
    columnHelper.accessor("stats.pagesWorkedOnThisYear", {
      header: "Pages worked",
      cell: ({ getValue }) => getValue()?.toLocaleString() ?? null,
    }),
    columnHelper.accessor("stats.approximateSavings", {
      header: "Savings on ink, copies, paper",
      cell: ({ row }) => row.original.stats.approximateSavingsDisplay ?? null,
    }),
    columnHelper.display({
      header: "Share",
      cell: ({ row }) => (
        <Button
          size="sm"
          onClick={() => {
            const organizationId: string = row.original.organization.id
            const usageStats: OrganizationUsageStats = row.original.stats
            return handleShareClick(organizationId, usageStats)
          }}
        >
          Share
        </Button>
      ),
    }),
    columnHelper.display({
      header: "Invite",
      cell: ({ row }) => (
        <Button
          size="sm"
          onClick={() => handleInviteClick(row.getValue("organization.id"))}
        >
          Invite
        </Button>
      ),
    }),
  ]

  return (
    <div className="tw-m-[1.4em]">
      <h2 className="tw-mb-4">All schools</h2>

      {data && <DataTable columns={columns} data={data} />}

      {isLoadingOverlayVisible && <LoadingOverlay />}
    </div>
  )
}
