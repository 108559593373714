import { type ClassValue, clsx } from "clsx"

/**
 * A helper function to process class names.
 * We may add more functionality in the future, such as with tailwind-merge.
 * @param inputs - The class names to process.
 * @returns The processed class names.
 */
export function classnames(...inputs: ClassValue[]): string {
  return clsx(inputs)
}

export const cn = classnames
