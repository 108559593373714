import { StrictMode } from 'react';

import { Container, createRoot } from 'react-dom/client';

import { App } from './App';
import { bootAngular } from './app/index.module';
import { reportWebVitals } from './reportWebVitals';

import 'mathlive/dist/mathlive-fonts.css';
import './index.scss';
import './index.css';

import 'jquery';
import 'jquery-ui'; // eslint-disable-line import/no-duplicates
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widget'; // eslint-disable-line import/no-duplicates
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/sortable';
import 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-clipboard';
import 'angular-cookies';
import 'angular-hotkeys';
import 'angular-material';
import 'angular-messages';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-ui-router';
import 'angular-ui-sortable';
import 'angularjs-scroll-glue';

const container = document.getElementById('root');
const root = createRoot(container as Container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

bootAngular();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
